import { consoleError } from '@/common/utils/console'
import { FlashMessage } from '@/providers'

export enum ExtensionErrors {
  NoSuchEntity = 'graphql-no-such-entity',
  Authorization = 'graphql-authorization',
  Internal = 'internal',
  Validation = 'validation',
}

export type CartErrors = {
  extensions: {
    category: ExtensionErrors
  }
  locations: {
    column: number
    line: number
  }[]
  message: string
  path: string[]
}

export const processAddToCartRawErrors = (
  rawErrors: string,
  customError?: string,
): FlashMessage[] => {
  let errors
  try {
    errors = JSON.parse(
      rawErrors.replace('Error: [{', '[{').replace('error: [{', '[{').trim(),
    )
  } catch (error) {
    consoleError(
      'process-add-to-cart-errors.ts [processAddToCartRawErrors]',
      error,
    )
    errors = [{ severity: 'error', text: customError }]
  }

  return processAddToCartErrors(errors)
}

export const processAddToCartErrors = (
  errors: CartErrors[],
  internalErrorTranslation?: string,
): FlashMessage[] => {
  return (errors ?? [])
    .filter((error) => !!error?.extensions?.category)
    .map(({ message, extensions }) => {
      if (extensions.category === ExtensionErrors.Internal) {
        return {
          severity: 'error',
          text: internalErrorTranslation ?? 'Internal server error',
        }
      }

      return { severity: 'error', text: message }
    })
}

export function isNotCartExists(rawErrors: string): boolean {
  if (rawErrors) {
    const errors = JSON.parse(rawErrors)

    return (
      (errors ?? []).filter(
        (error) =>
          !!error?.extensions?.category &&
          error.extensions.category === ExtensionErrors.NoSuchEntity,
      ).length > 0
    )
  }
  return false
}

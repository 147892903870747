import { HttpMethod, restFetch } from '../fetch'

const GET_DELIVERY_DATE_INFO_URL = '/api/rest/V1/deliverydate'

export interface DeliveryDateDataMethod {
  title: string
  delivery_date: string
  code: string
  price: number
  free_shipping_threshold: number
  method: string
}

export interface DeliveryDateData {
  title: string
  methods: DeliveryDateDataMethod[]
}

export const getDeliveryDateInfo = () =>
  restFetch<DeliveryDateData>(GET_DELIVERY_DATE_INFO_URL, HttpMethod.GET)

'use client'

import { useState, useEffect } from 'react'

import debounce from '../utils/debounce'

type WindowSize = {
  width?: number
  height?: number
}

const DefaultWindowSize = {
  width: undefined,
  height: undefined,
}

const RESIZE_DELAY = 100

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>(DefaultWindowSize)

  useEffect(() => {
    const handleResizeDebounced = debounce(() => {
      setWindowSize({
        width: document.body.clientWidth,
        height: window.innerHeight,
      })
    }, RESIZE_DELAY)

    handleResizeDebounced()

    window.addEventListener('resize', handleResizeDebounced)

    return () => {
      window.removeEventListener('resize', handleResizeDebounced)
    }
  }, [])

  return windowSize
}

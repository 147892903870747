export type MiddlewareHeaders = {
  [MiddlewareHeader.Store]: string
  [MiddlewareHeader.RequestUrl]: string
  [MiddlewareHeader.Authorization]?: string
  [MiddlewareHeader.Referer]?: string
  [MiddlewareHeader.OriginUrl]: string
}

export enum MiddlewareHeader {
  Authorization = 'Authorization',
  CustomerSegment = 'X-Customer-Segment',
  OriginUrl = 'Origin-Url',
  Pathname = 'Pathname',
  Referer = 'Referer',
  RequestUrl = 'RequestUrl',
  Store = 'Store',
  Cookie = 'Cookie',
  CustomerToken = 'customerToken',
  XForwardedUrl = 'X-Forwarded-Url',
}

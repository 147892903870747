'use client'

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { CartItemFragment, CartQuery } from '@/api'
import { StoreConfigType } from '@/common/types'
import { useAddToCart } from './hooks'
import { useAuthContext } from '../auth'
import { AddToCartParams, AddToCartTrackingConfig } from './cart.types'
import { fetchCartData, fetchCartId, trackAddToCartEvent } from './cart.utils'

type CartContext = {
  hasItems: boolean
  cartItems: CartItemFragment[]
  isCartFetchError: boolean
  isCartRefetching: boolean
  isAddingToCart: boolean
  addToCart?: (
    params: AddToCartParams,
    trackingConfig?: AddToCartTrackingConfig,
  ) => Promise<void>
}

export const CartContext = createContext<CartContext>({} as CartContext)

type CartContextProviderProps = {
  storeConfig: StoreConfigType
  // cartId?: string
}

/**
 * Provider that handles the cart
 * @param storeConfig
 * @param children
 * @constructor
 */
export const CartContextProvider = ({
  storeConfig,
  children,
}: // cartId,
PropsWithChildren<CartContextProviderProps>) => {
  const { customerToken, guestToken } = useAuthContext()
  const { isAddingToCart, addToCart, isCartFetchError } = useAddToCart()

  const defaultCartId = guestToken ?? ''
  const [currCartId, setCurrCartId] = useState(defaultCartId)
  const [cartData, setCartData] = useState<CartQuery>()
  const [isCartRefetching, setIsCartRefetching] = useState(false)

  useEffect(() => {
    fetchCartId({ customerToken, defaultCartId, onNewCartIdSet: setCurrCartId })
  }, [customerToken, defaultCartId])

  // TODO: implement notification for user to know that there was a problem to load cart data from the backend
  useEffect(() => {
    if (currCartId) {
      fetchCartData(currCartId, (cart) => {
        setCartData(cart)
      })
    }
  }, [currCartId])

  const addToCartWithCartId = useCallback(
    async (
      {
        brand,
        category,
        parentSku,
        productName,
        sku,
        price,
        quantity,
        isGiftCard,
        selectedOptions,
        prodlistPlacement,
        productIds,
        onSuccess,
        configId,
        simpleId,
        productPosition,
      }: AddToCartParams,
      trackingConfig?: AddToCartTrackingConfig,
    ) => {
      setIsCartRefetching(true)
      let latestCartId = currCartId
      const productInfo: AddToCartParams = {
        productName: productName ?? '',
        parentSku,
        sku: sku ?? '',
        quantity: quantity ?? 1,
        brand,
        price,
        category,
        selectedOptions,
        isGiftCard,
        configId,
        simpleId,
      }

      await addToCart({
        cartId: currCartId,
        refreshTokens: (token) => {
          latestCartId = token
          setCurrCartId(token)
        },
        onSuccess: () => {
          onSuccess?.()

          if (trackingConfig?.skipTracking) {
            return
          }

          trackAddToCartEvent(
            {
              ...productInfo,
              prodlistPlacement,
              productIds,
              productPosition,
              currency: storeConfig.currency,
            },
            Boolean(trackingConfig?.addingRecommendedProduct),
          )
        },
        ...productInfo,
      })

      fetchCartData(latestCartId, (cart) => {
        setCartData(cart)
        setIsCartRefetching(false)
      })
    },
    [currCartId, addToCart, storeConfig.currency],
  )

  const cartItems = useMemo(
    () =>
      ((cartData?.cart?.items || []).filter(Boolean) ??
        []) as CartItemFragment[],
    [cartData?.cart?.items],
  )

  const contextValue = useMemo(
    () => ({
      cartItems,
      isAddingToCart,
      isCartRefetching,
      isCartFetchError,
      hasItems: cartItems.length > 0,
      addToCart: addToCartWithCartId,
    }),
    [
      cartItems,
      isAddingToCart,
      isCartFetchError,
      isCartRefetching,
      addToCartWithCartId,
    ],
  )

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  )
}

export const useCartContext = () => useContext(CartContext)

import { z } from 'zod'

import {
  ConfigurableVariantAttribute,
  ProductVariant,
} from '@/components/product-list-item/product-data-item-types'
import {
  ConfigurableProductFragment,
  ConfigurableProductListItemFragment,
  ConfigurableProductModalDataFragment,
  CurrencyEnum,
  GiftCardProductFragment,
  GiftCardProductListItemFragment,
  GiftCardProductModalDataFragment,
  ProductStockStatus,
} from '../api/graphql'
import { FullyRequired } from './utility-types'
import { formatProductToListItem } from '../utils/product-utils'

export enum ProductType {
  Configurable = 'ConfigurableProduct',
  GiftCard = 'GiftCardProduct',
}

/** PRODUCT LIST TYPES */
export type ProductListItemData =
  | ConfigurableProductListItemData
  | GiftCardProductListItemData

export type ConfigurableProductListItemData =
  FullyRequired<ConfigurableProductListItemFragment>

export type GiftCardProductListItemData =
  FullyRequired<GiftCardProductListItemFragment>

/** PRODUCT MODAL TYPES */
export type ProductModalData =
  | ConfigurableProductModalData
  | GiftCardProductModalData

export type ConfigurableProductModalData =
  FullyRequired<ConfigurableProductModalDataFragment>

export type GiftCardProductModalData =
  FullyRequired<GiftCardProductModalDataFragment>

export type ConfigurableProductModalVariant = {
  is_bestseller?: boolean | null
  attributes?: Array<ConfigurableVariantAttribute> | null
  product?: ProductVariant | null
} | null

/** PRODUCT DETAIL TYPES */
export type ProductDetailData =
  | ConfigurableProductDetailData
  | GiftCardProductDetailData

export type ConfigurableProductDetailData =
  FullyRequired<ConfigurableProductFragment>

export type GiftCardProductDetailData = FullyRequired<GiftCardProductFragment>

export type ProductManufacturerInfoData = {
  __typename?: 'ManufacturerInfoInterface'
  name?: string | null
  pathname?: string | null
} | null

export type ConfigurableVariantsData = Array<{
  __typename?: 'ConfigurableVariant'
  is_bestseller?: boolean | null
  attributes?: Array<{
    __typename?: 'ConfigurableAttributeOption'
    code?: string | null
    label?: string | null
    value_index?: number | null
  } | null> | null
  product?: {
    __typename?: 'SimpleProduct'
    enabled?: boolean | null
    expiration_date?: string | null
    id?: number | null
    msrp_price?: number | null
    name?: string | null
    rating_summary: number
    review_count: number
    sku?: string | null
    stock_status?: ProductStockStatus | null
    allergens?: {
      __typename?: 'AllergensInfoInterface'
      allergens?: Array<string | null> | null
      traces?: Array<string | null> | null
    } | null
    estimated_date_of_arrival?: {
      __typename?: 'EstimatedDateOfArrivalType'
      timestamp?: string | null
      formatted_date?: string | null
    } | null
    price_tiers?: Array<{
      __typename?: 'TierPrice'
      final_price?: {
        __typename?: 'Money'
        currency?: CurrencyEnum | null
        value?: number | null
      } | null
    } | null> | null
    unit_price?: {
      __typename?: 'UnitPrice'
      value: number
      unit: string
    } | null
    visible_attributes?: Array<{
      __typename?: 'VisibleAttributes'
      label?: string | null
      code?: string | null
      values?: Array<{
        __typename?: 'VisibleAttributesValues'
        title?: string | null
        url?: string | null
        value?: string | null
      } | null> | null
    } | null> | null
    image?: {
      __typename?: 'ProductImage'
      disabled?: boolean | null
      label?: string | null
      position?: number | null
      url?: {
        __typename?: 'MediaGalleryUrlInterface'
        full?: string | null
        medium?: string | null
        small?: string | null
      } | null
    } | null
    price_range: {
      __typename?: 'PriceRange'
      maximum_price?: {
        __typename?: 'ProductPrice'
        final_price: {
          __typename?: 'MoneyTaxExtended'
          currency?: CurrencyEnum | null
          value?: number | null
          value_excl_tax?: number | null
        }
        regular_price: {
          __typename?: 'MoneyTaxExtended'
          currency?: CurrencyEnum | null
          value?: number | null
          value_excl_tax?: number | null
        }
      } | null
      minimum_price: {
        __typename?: 'ProductPrice'
        final_price: {
          __typename?: 'MoneyTaxExtended'
          currency?: CurrencyEnum | null
          value?: number | null
          value_excl_tax?: number | null
        }
      }
    }
  } | null
} | null> | null

export type ProductListItemType = {
  formatted_price: string | null
  id: number | null
  image: string | null
  is_saleable: string | null
  name: string | null
  price: number | null
  product_url: string | null
  rating_summary: string | null
  reviews_count: string | null
  skus: Array<string | null> | null
  small_image: string | null
  thumbnail: string | null
  am_labels: Array<{
    image: string | null
    image_size: string | null
    label_text: string | null
    position: number | null
    style: string | null
    type: string | null
  } | null> | null
  manufacturer: {
    id: number | null
    name: string | null
  } | null
  default_category: {
    id: number | null
    name: string | null
  } | null
  breadcrumb_en: string | null
  product_labels:
    | {
        bg: string
        content: string
        discount: null
        label: number
        src: string
        type: string
      }[]
    | null
}

export type ThumbnailData = {
  __typename?: 'ProductImage'
  label?: string | null
  url?: {
    __typename?: 'MediaGalleryUrlInterface'
    medium?: string | null
  } | null
} | null

export type PriceRangeData = {
  __typename?: 'PriceRange'
  maximum_price?: {
    __typename?: 'ProductPrice'
    final_price: {
      __typename?: 'MoneyTaxExtended'
      currency?: CurrencyEnum | null
      value?: number | null
      value_excl_tax?: number | null
    }
    regular_price: {
      __typename?: 'MoneyTaxExtended'
      currency?: CurrencyEnum | null
      value?: number | null
      value_excl_tax?: number | null
    }
  } | null
  minimum_price: {
    __typename?: 'ProductPrice'
    final_price: {
      __typename?: 'MoneyTaxExtended'
      currency?: CurrencyEnum | null
      value?: number | null
      value_excl_tax?: number | null
    }
  }
}

export type GiftCardAmountsData = Array<{
  __typename?: 'GiftCardAmounts'
  uid: string
  website_value?: number | null
} | null> | null

export function obsoleteIsGiftCardProductListItem(
  productData: ConfigurableProductListItemData | GiftCardProductListItemData,
): productData is GiftCardProductListItemData {
  return productData?.__typename === ProductType.GiftCard
}

export function obsoleteIsConfigurableProductListItem(
  productData: ConfigurableProductListItemData | GiftCardProductListItemData,
): productData is ConfigurableProductListItemData {
  return productData?.__typename === ProductType.Configurable
}

export function isGiftCardProductDetail(
  productData: ConfigurableProductFragment | GiftCardProductFragment,
): productData is GiftCardProductFragment {
  return productData?.__typename === ProductType.GiftCard
}

export function isConfigurableProductDetail(
  productData: ConfigurableProductFragment | GiftCardProductFragment,
): productData is ConfigurableProductFragment {
  return productData?.__typename === ProductType.Configurable
}

const StringifiedBooleanSchema = z.union([z.literal('0'), z.literal('1')])
const TestVariantSchema = z.object({
  name: z.string(),
  variant: z.string(),
})

const AWSRecommendedProductsInputVariablesSchema = z.object({
  productID: z.string(),
  locale: z.string(),
  limit: z.number(),
  userConsent: z.boolean(),
  userPID: z.optional(z.string()),
  userID: z.optional(z.number()),
  userCT: z.optional(z.string()),
  tests: z.array(TestVariantSchema),
})
export const AWSRecommendedProductsInputSchema = z.object({
  operationName: z.literal('getProducts'),
  variables: AWSRecommendedProductsInputVariablesSchema,
})
export type AWSRecommendedProductsInput = z.infer<
  typeof AWSRecommendedProductsInputSchema
>

const ProductDisplaySchema = z.union([z.literal('config'), z.literal('simple')])

export const RecommendedProductAPISchema = z.object({
  product_order: z.number(),
  product_display: ProductDisplaySchema,
  product_id_config: z.string(),
  product_sku_config: z.string(),
  product_id_simple: z.string(),
  product_sku_simple: z.string(),
})
export type RecommendedProductAPI = z.infer<typeof RecommendedProductAPISchema>

export type RecommendedProduct = {
  productOrder: number
  productDisplay: 'config' | 'simple'
  productIdConfig: string
  productSkuConfig: string
  productIdSimple: string
  productSkuSimple: string
}

export const RecommendedProductsAPISchema = z.object({
  data: z.object({
    tests: z.array(TestVariantSchema),
    products: z.array(RecommendedProductAPISchema),
  }),
})
export type RecommendedProductsAPI = z.infer<
  typeof RecommendedProductsAPISchema
>

export type RecommendedProducts = {
  tests: {
    name: string
    variant: string
  }[]
  products: RecommendedProduct[]
}

const ProductLabelSchema = z.object({
  label: z.string(),
  type: z.string(),
  bg: z.string(),
  content: z.string(),
  src: z.optional(z.string()),
  discount: z.optional(z.number()),
})

const PriceSchema = z.object({
  price: z.coerce.number(),
  final_price: z.coerce.number(),
  formatted_price: z.string(),
})

const ProductBySKUPriceSchema = PriceSchema.extend({
  min_price: z.coerce.number(),
  max_price: z.coerce.number(),
})

const ProductBySKUVariantPriceSchema = PriceSchema.extend({
  min_price: z.nullable(z.coerce.number()),
  max_price: z.nullable(z.coerce.number()),
})

const ThumbnailSchema = z.object({
  url: z.string(),
  alt: z.nullable(z.string()),
})

const VariantAttributesSchema = z.object({
  code: z.string(),
  label: z.string(),
  value_index: z.number(),
})

const ManufacturerInfoSchema = z.object({
  name: z.string(),
  pathname: z.string(),
})

const ConfigurableVariantProductSchema = z.object({
  id: z.string(),
  sku: z.string(),
  name: z.string(),
  type: z.string(),
  is_saleable: z.boolean(),
  is_bestseller: z.nullable(StringifiedBooleanSchema),
  long_term_unavailable: StringifiedBooleanSchema,
  product_labels: z.array(ProductLabelSchema),
  manufacturer_info: ManufacturerInfoSchema,
  price: ProductBySKUVariantPriceSchema,
  product_url: z.string(),
  thumbnail: ThumbnailSchema,
  image: ThumbnailSchema,
})

const ConfigurableVariantItemSchema = z.object({
  parent_sku: z.string(),
  is_bestseller: z.nullable(StringifiedBooleanSchema),
  attributes: z.array(VariantAttributesSchema),
  product: ConfigurableVariantProductSchema,
})

export type ConfigurableVariantItem = z.infer<
  typeof ConfigurableVariantItemSchema
>

export const ProductBySKUsSchema = z.object({
  id: z.string(),
  sku: z.string(),
  name: z.string(),
  type: z.enum(['configurable', 'giftcard']),
  is_saleable: z.boolean(),
  is_bestseller: z.nullable(StringifiedBooleanSchema),
  long_term_unavailable: StringifiedBooleanSchema,
  product_labels: z.optional(z.array(ProductLabelSchema)),
  manufacturer_info: ManufacturerInfoSchema,
  price: ProductBySKUPriceSchema,
  product_url: z.string(),
  thumbnail: ThumbnailSchema,
  image: ThumbnailSchema,
  'rating-result': z.coerce.number(),
  reviews_count: z.coerce.number(),
  breadcrumb_en: z.string(),
  canonical_url: z.string(),
  configurable_variants: z.optional(z.array(ConfigurableVariantItemSchema)),
})

export type ProductBySKUs = z.infer<typeof ProductBySKUsSchema>
export type ProductListItem = ReturnType<typeof formatProductToListItem>

export const RecommendedProductsInputSchema = z.object({
  locale: z.string(),
  productId: z.string(),
  customerId: z.number().optional(),
  customerToken: z.string().optional(),
  limit: z.number().optional(),
})

export type RecommendedProductsInput = z.infer<
  typeof RecommendedProductsInputSchema
>

import { arePricesDifferentInVariants } from '.'
import { CurrencyEnum, ProductStockStatus } from '../api/graphql'
import { HttpMethod } from '../api/rest/fetch'
import {
  ConfigurableVariantItem,
  ProductBySKUs,
  ProductListItem,
  RecommendedProduct,
  RecommendedProducts,
  RecommendedProductsInput,
} from '../types/product-types'
import { Currency } from '@/common/types/price-types'
import { PriceRange } from '@/components/product-list-item/product-data-item-types'
import { consoleError } from './console'
import { StoreCodeType } from '../types'

export enum ListItemProductType {
  GiftCard = 'giftcard',
  Configurable = 'configurable',
}

export const getPositionOfProduct = (
  recommendedProducts: RecommendedProduct[],
  variants: ConfigurableVariantItem[],
) => {
  const prodSimpleIds = variants.map((variant) => variant.product.id)
  const recommendedSimpleIds = recommendedProducts.map(
    (prod) => prod.productIdSimple,
  )
  return (
    recommendedSimpleIds.findIndex((simpleId) =>
      prodSimpleIds.includes(simpleId.toString()),
    ) + 1
  )
}

export const processRecommendedItems = (
  limit: number,
  products: ProductListItem[],
  recommendedProducts: RecommendedProduct[],
): ProductListItem[] => {
  return products
    .filter((prod) => prod.isSaleable && prod.longTermUnavailable === 0)
    .slice(0, limit)
    .sort((a, b) =>
      getPositionOfProduct(recommendedProducts, a.configurableVariants ?? []) <=
      getPositionOfProduct(recommendedProducts, b.configurableVariants ?? [])
        ? -1
        : 1,
    )
}

const getProductListItemType = (
  type: string,
): ListItemProductType | undefined => {
  if (
    type === ListItemProductType.Configurable ||
    type === ListItemProductType.GiftCard
  ) {
    return type
  }

  return undefined
}

function getPriceRangeOfConfigurable(
  configurable: ProductBySKUs,
  currency?: CurrencyEnum,
): PriceRange {
  return {
    isPriceRange: configurable.configurable_variants
      ? configurable.configurable_variants.length >= 2 &&
        arePricesDifferentInVariants(
          configurable.configurable_variants.map(
            (variant) => variant.product.price.final_price,
          ),
        )
      : false,
    price: configurable.price.min_price,
    currency: currency || Currency.Eur,
  }
}

function getPriceRangeOfGiftCard(
  giftCard: any,
  currency?: CurrencyEnum,
): PriceRange {
  return {
    currency: currency || Currency.Eur,
    price: giftCard.price.min_price,
    isPriceRange: true,
  }
}

export function formatProductToListItem(
  product: ProductBySKUs,
  storeConfigCurrency?: CurrencyEnum,
) {
  const priceRange =
    product.type === ListItemProductType.Configurable
      ? getPriceRangeOfConfigurable(product, storeConfigCurrency)
      : getPriceRangeOfGiftCard(product, storeConfigCurrency)

  const isProductSingleVariant =
    product.configurable_variants?.length === 1 ?? false
  const singleVariantSku = isProductSingleVariant
    ? product.configurable_variants?.[0].product.sku ?? ''
    : product.sku
  const ratingSummary = Number(product?.['rating-result'] ?? '0')
  const stockStatus = product?.is_saleable
    ? ProductStockStatus.InStock
    : ProductStockStatus.OutOfStock
  const thumbnail = {
    url: product?.image.url,
    label: product?.image.alt,
  }
  const enabled = product?.long_term_unavailable === '0'
  const reviewCount = Number(product?.reviews_count) ?? 0
  const sku = product.sku
  const canonicalUrl = new URL(product.canonical_url).pathname
  const skus =
    product.configurable_variants?.map((variant) => variant.product.sku) ?? []
  const image = {
    url: {
      small: product.image.url,
    },
    label: product.image.alt,
  }
  const longTermUnavailable = Number(product.long_term_unavailable) ?? null
  const breadcrumbEn = product.breadcrumb_en
  const productLabels =
    product.product_labels?.map((label) => ({
      ...label,
      label: Number(label.label),
    })) ?? []

  return {
    name: product.name,
    manufacturerInfo: {
      name: product.manufacturer_info.name,
      pathname: product.manufacturer_info.pathname,
    },
    finalPrice: product.price.final_price,
    priceRange,
    isProductSingleVariant,
    ratingSummary,
    stockStatus,
    thumbnail,
    enabled,
    reviewCount,
    sku,
    singleVariantSku,
    canonicalUrl,
    skus,
    id: product.id,
    image,
    longTermUnavailable,
    breadcrumbEn,
    productLabels,
    isSaleable: product.is_saleable,
    isBestseller: product.is_bestseller === '1',
    configurableVariants: product.configurable_variants ?? [],
    type: getProductListItemType(product.type),
  }
}

export async function getProductsBySKUs({
  limit,
  skus,
  storeCode,
}: {
  skus: string[]
  limit: number
  storeCode: StoreCodeType
}): Promise<ProductListItem[]> {
  try {
    const searchParams = new URLSearchParams()
    searchParams.append('skus', skus.join(','))
    searchParams.append('limit', limit.toString())
    searchParams.append('storeCode', storeCode)

    const res = await fetch(
      `/api/v1/products-by-skus?${searchParams.toString()}`,
    )
    const result: ProductListItem[] = await res.json()

    return result
  } catch (err) {
    consoleError('utils/product-utils.ts [getProductsBySKUs]', err)
    return []
  }
}

export async function getRecommendedProducts(
  input: RecommendedProductsInput,
): Promise<RecommendedProducts> {
  try {
    const res = await fetch(`/api/v1/recommended-products`, {
      method: HttpMethod.POST,
      body: JSON.stringify(input),
    })
    const result: RecommendedProducts = await res.json()

    return result
  } catch (err) {
    consoleError('utils/product-utils.ts [getRecommendedProducts]', err)
    return {
      products: [],
      tests: [],
    }
  }
}
